import React, { useEffect } from "react";

import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
// import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

// const useStyles = makeStyles({
//   button: {
//     backgroundColor: "#303d6b",
//     color: "white",
//     boxShadow: " 6px 8px 3px #00000055",
//     "&:hover": {
//       backgroundColor: "#ff6105",
//       boxShadow: " 6px 8px 3px #00000055",
//     },
//     "&:active": {
//       backgroundColor: "#ff6105",
//       boxShadow: " 4px 5px 1px #00000055",
//     },
//   },
// });

export default function End(props) {
  //   const classes = useStyles();
  // useEffect(() => {
  //   setTimeout(() => {
  //     window.location.href = "https://graphisoft-nord.de";
  //   }, 8000);
  // }, []);

  return (
    <Container>
      <Grid container justify="center" alignItems="center" spacing={3}>
        <Grid item md={10} xs={12} align="center">
          <Typography variant="h4" align="left">
            Evaluierung abgeschlossen
          </Typography>
          <br></br>
          <br></br>
          <Typography variant="h5" align="left">
            Einzelanmeldungen erhalten Ihr Ergebnis unmittelbar per E-Mail. Bei
            Gruppenanmeldungen werden die Ergebnisse an den Ansprechpartner
            versendet, sobald alle Teilnehmer die Evaluierung abgeschlossen
            haben.
          </Typography>
          {/* <Typography variant="h5" align="left">
                        {props.correctAnswers + " von " + props.setup.questions_length} Fragen richtig beantwortet ({(props.correctAnswers * 100 / props.setup.questions_length).toFixed(2)}%)
                    </Typography> */}
        </Grid>
        {/* <Grid item md={10} xs={12} align="right">
                    <Button className={classes.button} variant="contained" onClick={() => props.submitAnswers()}>
                        <Typography>
                            Absenden
                        </Typography>
                    </Button>
                </Grid> */}
      </Grid>
    </Container>
  );
}
