import {
  Container,
  Grid,
  Paper,
  Snackbar,
  Typography,
} from "@material-ui/core";
import axios from "axios";
import { useEffect, useState } from "react";
import "./App.css";

import MuiAlert from "@material-ui/lab/Alert";

import { v4 as uuidv4 } from "uuid";

import End from "./End";
import Footer from "./Footer";
import Form from "./Form";
import Quiz from "./Quiz";

import Logo from "./images/GraphisoftNordGmbH.png";
import SchnitzerLogo from "./images/schnitzer_logo.png";

const session_id = uuidv4();
const company_id = Math.random().toString(36).substr(2, 9);

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

function App() {
  const API_PATH = process.env.REACT_APP_API_URL;
  // const customer_id = window.location.search.substring(1);
  const [customer_id, setCustomer_id] = useState(
    window.location.search.substring(1)
  );

  const customer_model = {
    session_id: session_id,
    name: "",
    surname: "",
    email: "",
    customer_id: uuidv4(),
    company_id: company_id,
    company_name: "",
    company_email: "",
    company_booker_name: "",
  };

  const [page, setPage] = useState(0);
  const [setup, setSetup] = useState(undefined);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarText, setSnackbarText] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("");

  useEffect(() => {
    axios.get(API_PATH + "getSetup").then((json_setup) => {
      let newSetup = json_setup.data[0];
      setSetup(newSetup);
    });
  }, []);

  // validating over customer_id if active
  useEffect(() => {
    if (customer_id) {
      axios.get(API_PATH + "isCustomer/" + customer_id).then((resp) => {
        const customer = resp.data.customer;
        const whitelist = resp.data.whitelist;
        console.log(customer);
        console.log(whitelist);
        if (customer) {
          if (whitelist) {
            setPage(1);
          } else if (customer.active === 2) {
            setPage(1);
          } else {
            setPage(4);
            setTimeout(() => {
              setPage(0);
            }, 2000);
          }
        } else console.log("id unknown");
      });
    }
  }, [customer_id]);

  const handleSnackbar = (severity, text) => {
    setOpenSnackbar(false);
    setSnackbarText(text);
    setSnackbarSeverity(severity);
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  function handlePageChange(page) {
    switch (page) {
      case 1:
        return (
          <Quiz
            setup={setup}
            API_PATH={API_PATH}
            setPage={setPage}
            end={false}
            handleSnackbar={handleSnackbar}
            customer_id={customer_id}
          />
        );
      case 2:
        return (
          <End
            setup={setup}
            setPage={setPage}
            handleSnackbar={handleSnackbar}
          />
        );
      case 3:
        // setTimeout(() => {
        //   window.location.href = "https://graphisoft-nord.de";
        // }, 8000);
        return (
          <p>
            Vielen Dank für die Anmeldung, Sie erhalten den Link zum
            Freischalten des Tests nach der Prüfung Ihrer Daten per E-Mail.
          </p>
        );
      case 4:
        // setTimeout(() => {
        //   window.location.href = "https://graphisoft-nord.de";
        // }, 8000);
        return (
          <p>
            Sie haben bereits einen Einstufungstest durchgeführt, bitte wenden
            Sie Sich an den Support
          </p>
        );
      case 5:
        // setTimeout(() => {
        //   window.location.href = "https://graphisoft-nord.de";
        // }, 8000);
        return <p>Sie sind nicht berechtigt!</p>;

      case 6:
        // setTimeout(() => {
        //   window.location.href = "https://graphisoft-nord.de";
        // }, 8000);
        return (
          <p>
            Sie sind bereits registriert und verifiziert! Die E-Mail mit dem
            Link zum Test wird erneut an Sie versendet.
          </p>
        );
      default:
        return (
          <Form
            customer_model={customer_model}
            handleSnackbar={handleSnackbar}
            setup={setup}
            setPage={setPage}
            setCustomer_id={setCustomer_id}
          ></Form>
        );
    }
  }

  return (
    <div className="App">
      <Container>
        <Grid container justify="center" alignItems="center" spacing={3}>
          <Grid item align="center" xs={12} md={5}>
            <Typography variant="h4" align="center" style={{ fontWeight: 600 }}>
              Archicad Evaluierungstest
            </Typography>
          </Grid>
          <Grid item align="right" xs={12} md={3}>
            <img
              src={SchnitzerLogo}
              alt="logo_schnitzer&"
              style={{
                padding: 30,
                // width: "40%",
                minWidth: "100px",
                maxWidth: "180px",
              }}
            />
          </Grid>
          <Grid item align="left" xs={12} md={4}>
            <img
              src={Logo}
              alt="logo"
              style={{
                padding: 30,
                // width: "40%",
                minWidth: "100px",
                maxWidth: "300px",
              }}
            />
          </Grid>
        </Grid>
        <Paper elevation={3}>
          <Grid container justify="center" spacing={6}>
            {page === 0 ? (
              <Grid item md={10} xs={12}>
                <br></br>
                <Typography variant="h5" align="left">
                  Anmeldung zum Archicad Evaluierungstest
                </Typography>
                <br></br> <br></br>
                <Typography variant="h6" align="left">
                  Willkommen bei unserer Evaluierung. Mit Hilfe dieser Fragen
                  können wir Ihren derzeitigen Wissensstand im Bereich Archicad
                  einordnen und Ihnen darauf basierende Schulungsempfehlungen
                  erteilen.
                </Typography>
                <hr></hr>
              </Grid>
            ) : null}
          </Grid>
          <br />
          <br />
          {/* {loading ? <CircularProgress /> : handlePageChange(page)} */}
          {setup && handlePageChange(page)}
          <br />
          <br />
        </Paper>
      </Container>
      <Footer />
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
          {snackbarText}
        </Alert>
      </Snackbar>
    </div>
  );
}

export default App;
