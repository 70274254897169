import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useState } from "react";

import DeleteIcon from "@material-ui/icons//Delete";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import PlayCircleFilledWhiteIcon from "@material-ui/icons/PlayCircleFilledWhite";
import FormSingle from "./FormSingle";
import FormMulti from "./FormMulti";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      key={"Tabkey" + index}
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const useStyles = makeStyles({
  textfield: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#ff4545",
      },
    },
  },
  validTextfield: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#303d6b",
      },
    },
  },
});

export default function Form(props) {
  const { handleSnackbar, setup, setPage, setCustomer_id } = props;
  const [tabIndex, setTabIndex] = useState(1); // TODO set to 0

  const handleChangeTab = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <Grid container alignItems="center" justify="center" spacing={3}>
      <Grid item xs={12} md={10}>
        <Typography variant="h6" align="left">
          Geben Sie Ihre Daten ein, um den Evaluierungstest durchzuführen. Nach
          der Prüfung Ihrer Daten bekommen Sie den Link zum Test per E-Mail
          gesendet.
        </Typography>
      </Grid>
      {/* <Grid item xs={12} md={10}>
        <Tabs
          value={tabIndex}
          onChange={handleChangeTab}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: "divider" }}
        >
          <Tab
            style={
              tabIndex === 0
                ? { backgroundColor: "#303d6b", color: "white" }
                : {}
            }
            label="Als Büro anmelden"
            {...a11yProps(0)}
          />
          <Tab
            style={
              tabIndex === 1
                ? { backgroundColor: "#ff6105", color: "white" }
                : {}
            }
            label="Alleine anmelden"
            {...a11yProps(1)}
          />
        </Tabs>
      </Grid> */}
      <Grid item xs={12} align="left" md={10}>
        {/* <TabPanel value={tabIndex} index={0}>
          <FormMulti
            handleSnackbar={handleSnackbar}
            setup={setup}
            setPage={setPage}
          />
        </TabPanel>
        <TabPanel value={tabIndex} index={1}> */}
        <FormSingle
          handleSnackbar={handleSnackbar}
          setup={setup}
          setPage={setPage}
          setCustomer_id={setCustomer_id}
        />
        {/* </TabPanel> */}
      </Grid>
      <Grid item xs={10} align="left" md={10}></Grid>
    </Grid>
  );
}
