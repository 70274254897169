import React from "react";
import Link from "@material-ui/core/Link";
import Container from "@material-ui/core/Container";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  container: {
    backgroundColor: "#ff6105",
    position: "fixed",
    bottom: 0,
    maxWidth: "100%",
    display: "flex",
    justifyContent: "center",
  },
});

export default function Register(props) {
  const classes = useStyles();
  return (
    <Container className={classes.container}>
      <Breadcrumbs
        aria-label="breadcrumb"
        align="center"
        style={{ color: "white" }}
      >
        <Link color="inherit" href="https://www.schnitzerund.de/">
          © {new Date().getFullYear()} Schnitzer&
        </Link>
        <Link
          color="inherit"
          target="blank"
          href="https://www.schnitzerund.de/datenschutz"
        >
          Datenschutz
        </Link>
        <Link
          color="inherit"
          target="blank"
          href="https://www.schnitzerund.de/impressum"
        >
          Impressum
        </Link>
        <Link color="inherit" href="https://graphisoft-nord.de/">
          © {new Date().getFullYear()} GRAPHISOFT Nord GmbH
        </Link>
        <Link
          color="inherit"
          target="blank"
          href="https://graphisoft-nord.de/datenschutz/"
        >
          Datenschutz
        </Link>
        <Link
          color="inherit"
          target="blank"
          href="https://graphisoft-nord.de/impressum/"
        >
          Impressum
        </Link>
      </Breadcrumbs>
    </Container>
  );
}
