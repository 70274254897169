import axios from "axios";
import React, { useEffect, useState } from "react";

import Container from "@material-ui/core/Container";
import { makeStyles, withStyles } from "@material-ui/core/styles";

import Button from "@material-ui/core/Button";

import Typography from "@material-ui/core/Typography";

import Grid from "@material-ui/core/Grid";

import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from "@material-ui/core/LinearProgress";
import PropTypes from "prop-types";
import {
  Checkbox,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
} from "@material-ui/core";

function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress
        variant="determinate"
        {...props}
        style={{ width: 120, marginRight: 80, color: "#ff6105" }}
      />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          component="div"
          color={props.answer === "timeout" ? "error" : "textSecondary"}
          style={{ fontSize: 40 }}
          align="center"
        >{`${Math.round(props.text)}`}</Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
  text: PropTypes.number.isRequired,
};

const useStyles = makeStyles({
  container: {
    maxWidth: "80%",
  },
  headerContainer: {
    padding: "50px",
  },
  headerContainerItem: {
    zIndex: 1,
  },
  logo: {
    textAlign: "left",
    width: "50%",
  },
  icon: {
    maxWidth: "90px",
    maxHeight: "90px",
  },
  button: {
    backgroundColor: "#303d6b",
    color: "white",
    boxShadow: " 6px 8px 3px #00000055",
    "&:hover": {
      backgroundColor: "#ff6105",
      boxShadow: " 6px 8px 3px #00000055",
    },
    // '&:disabled': {
    //     backgroundColor: '#303d6b',
    //     boxShadow: "0 0 0 #00000055",
    //     color: "white"
    // },
    "&:active": {
      backgroundColor: "#ff6105",
      boxShadow: " 4px 5px 1px #00000055",
    },
  },

  buttonAnswer: {
    minHeight: "180px",
    textTransform: "none",
    backgroundColor: "#303d6b",
    boxShadow: " 6px 8px 3px #00000055",
    "&:hover": {
      backgroundColor: "#ff6105",
      boxShadow: " 6px 8px 3px #00000055",
    },
    "&:disabled": {
      boxShadow: "0 0 0 #00000055",
      color: "white",
    },
    "&:active": {
      backgroundColor: "#ff6105",
      boxShadow: " 4px 5px 1px #00000055",
    },
  },

  buttonAnswerClicked: {
    minHeight: "180px",
    backgroundColor: "#ff6105",
    textTransform: "none",
    boxShadow: " 6px 8px 3px #00000055",
    "&:hover": {
      backgroundColor: "#ff6105",
      boxShadow: " 6px 8px 3px #00000055",
    },
    "&:disabled": {
      backgroundColor: "#ff6105",
      boxShadow: "0 0 0 #00000055",
      color: "white",
    },
    "&:active": {
      backgroundColor: "#ff6105",
      boxShadow: " 4px 5px 1px #00000055",
    },
  },

  buttonAnswerText: {
    padding: "4px",
    fontSize: "0.9vw",
  },
  image: {
    maxWidth: "100%",
    maxHeight: "500px",
  },
  // image: {
  //   maxWidth: "200px",
  //   maxHeight: "200px",
  // },
});

// const answer_time = 30

export default function Quiz(props) {
  const { API_PATH, setup, setPage, handleSnackbar, customer_id } = props;
  const classes = useStyles();
  const [timer, setTimer] = useState(setup.answer_time || 35);
  const [percent, setPercent] = useState(100);
  const [question, setQuestion] = useState({});
  const [active, setActive] = useState({});
  const [clicked, setClicked] = useState(false);
  const [start, setStart] = useState(false);
  const [wantTraining, setWantTraining] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [evalCustomer, setEvalCustomer] = useState({});
  const [answers, setAnswers] = useState([]);
  const [answersByCategory, setAnswersByCategory] = useState([]);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [answerRandomArray, setAnswerRandomArray] = useState([
    "answer_good",
    "answer_bad_1",
    "answer_bad_2",
  ]);
  const [duration, setDuration] = useState(0);

  let newAnswerRandomArray;

  // get questions
  useEffect(() => {
    axios
      .get(API_PATH + "getQuestions")
      .then((json_questions) => {
        let qs = json_questions.data;
        console.log(qs);
        fisherYatesShuffle(qs);
        console.log(qs);
        setQuestions(qs);
        setQuestion(qs[0]);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (answers.length > 0) {
      let usedCategories = setup.categories;
      usedCategories = JSON.parse(usedCategories);
      usedCategories.forEach((category) => {
        delete category.created_at;
        delete category.updated_at;
        delete category.deleted_at;
        category.points = 0;
        category.questions_length = 0;

        answers.forEach((answer) => {
          if (category.id === answer.category_id) {
            category.questions_length++;
            if (answer.id === "answer_good") {
              category.points++;
            }
          }
        });
      });
      setAnswersByCategory(usedCategories);
    }
  }, [answers, setup.categories]);

  useEffect(() => {
    if (start) {
      let faktor = 100 / timer;
      const interval = setInterval(() => {
        if (!clicked) {
          setPercent((prevProgress) => prevProgress - faktor);
          setTimer((prevProgress) =>
            prevProgress > 0 ? prevProgress - 1 : noClick()
          );
        }
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [clicked, start]);

  function fisherYatesShuffle(arr) {
    for (var i = arr.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1)); //random index
      [arr[i], arr[j]] = [arr[j], arr[i]]; // swap
    }
  }
  useEffect(() => {
    let id = customer_id || window.location.search.substr(1);
    axios.get(API_PATH + "getCustomer/" + id).then((json_customers) => {
      setEvalCustomer(json_customers.data[0]);
    });
    setQuestion({ ...questions[questionIndex] });
    newAnswerRandomArray = answerRandomArray;
    fisherYatesShuffle(newAnswerRandomArray);
    setAnswerRandomArray(newAnswerRandomArray);
  }, [questionIndex]);

  const startQuiz = () => {
    axios
      .post(API_PATH + "startTest/", {
        customer_id: evalCustomer.customer_id,
        wantTraining,
      })
      .then(function (response) {
        // console.log(response); // TODO Doppelte Fenster abfangen
        setStart(true);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const trainingWithoutTest = () => {
    setSubmitting(true);
    let customer = {
      ...evalCustomer,
    };

    let answer = {
      customer_id: customer.customer_id,
      session_id: customer.session_id,
      company_id: customer.company_id,
      points: "Schulung",
      duration: 0,
      questions_length: 0,
    };
    axios
      .post(API_PATH + "setAnswerMulti/", {
        customer,
        answer,
        setup,
      })
      .then(function () {
        handleSnackbar("success", "Erfolgreich übermittelt");
        // setTimeout(() => {
        //   window.location.href = "https://graphisoft-nord.de";
        // }, 5000);
        // setPage(2);
      })
      .catch(function (error) {
        console.log(error);
        handleSnackbar(
          "error",
          "Ein Fehler ist aufgetreten, bitte versuchen Sie es später noch einmal!"
        );
      });
    // axios
    //   .post(API_PATH + "needTraining/", {
    //     customer: evalCustomer,
    //   })
    //   .then(function (response) {
    //     handleSnackbar("success", "Erfolgreich übermittelt!");
    //     setTimeout(() => {
    //       window.location.href = "https://graphisoft-nord.de";
    //     }, 5000);
    //   })
    //   .catch(function (error) {
    //     setSubmitting(false);
    //     console.log(error);
    //   });
  };

  const buttonClick = (answer_name, index) => {
    setClicked(true);
    setActive({ [index]: true });
    console.log(questions);
    let category_id = questions[questionIndex].category_id;

    let answerObj = {
      id: answer_name,
      category_id: category_id,
    };
    setDuration(duration + (setup.answer_time - timer));
    setAnswers([...answers, answerObj]);
  };

  const noClick = () => {
    setClicked(true);
    setPercent(0);
    setTimer(0);
    let category_id = questions[questionIndex].category_id;
    let answerObj = {
      id: "timeout",
      category_id: category_id,
    };
    setDuration(duration + (setup.answer_time - timer));
    setAnswers([...answers, answerObj]);
  };

  const submitAnswers = () => {
    let points = JSON.stringify(answersByCategory);
    let answer = {
      customer_id: evalCustomer.customer_id,
      session_id: evalCustomer.session_id,
      company_id: evalCustomer.company_id,
      points,
      duration,
      questions_length: setup.questions_length,
    };
    let customer = {
      ...evalCustomer,
      points,
      duration,
      questions_length: setup.questions_length,
    };
    customer?.company_email.length > 0
      ? axios
          .post(API_PATH + "setAnswerMulti/", {
            customer,
            answer,
            setup,
          })
          .then(function () {
            handleSnackbar("success", "Antworten erfolgreich übermittelt");
            setPage(2);
          })
          .catch(function (error) {
            console.log(error);
            handleSnackbar(
              "error",
              "Ein Fehler ist aufgetreten, bitte versuchen Sie es später noch einmal!"
            );
          })
      : axios
          .post(API_PATH + "setAnswerSingle/", {
            customer,
            answer,
            setup,
          })
          .then(function () {
            handleSnackbar("success", "Antworten erfolgreich übermittelt");
            setPage(2);
          })
          .catch(function (error) {
            console.log(error);
            handleSnackbar(
              "error",
              "Ein Fehler ist aufgetreten, bitte versuchen Sie es später noch einmal!"
            );
          });
  };

  const nextQuestion = () => {
    setActive({});
    setTimer(setup.answer_time);
    setPercent(100);
    setClicked(false);
    if (questionIndex + 1 === setup.questions_length) {
      setPage(2);
      submitAnswers();
    } else setQuestionIndex(questionIndex + 1);
  };

  const makeBold = (input) => {
    var output = input;

    if (
      input.includes("{b") ||
      input.includes("{i") ||
      input.includes("{u") ||
      input.includes("{s")
    ) {
      output = output.replace(/{b /g, "<b>");
      output = output.replace(/ b}/g, "</b>");
      output = output.replace(/{i /g, "<i>");
      output = output.replace(/ i}/g, "</i>");
      output = output.replace(/{u /g, "<u>");
      output = output.replace(/ u}/g, "</u>");
      output = output.replace(/{s /g, "<s>");
      output = output.replace(/ s}/g, "</s>");
      return output;
    } else return input;
  };
  return (
    <Container>
      {!start ? (
        <Grid container justify="center" alignItems="center" spacing={5}>
          <Grid item md={10} xs={12} align="left">
            <br></br>
            <Typography variant="h6" align="left">
              Hallo {evalCustomer.name} {evalCustomer.surname}, willkommen zum
              Archicad Evaluierungstest
            </Typography>
          </Grid>
          <Grid item xs={12} md={10}>
            <Typography variant="subtitle1" align="left">
              Einige Bitten noch vor dem Testbeginn: <br />
              <ul>
                <li>
                  Arbeiten Sie diesen Test in Ruhe und möglichst ohne
                  Ablenkungen von außen durch.
                </li>
                <li>
                  Planen Sie ca. 45 Minuten für die Beantwortung der Fragen ein.
                </li>
              </ul>
            </Typography>
            <Typography variant="subtitle1" align="left">
              Beachten Sie bitte, dass Sie: <br />
              <ul>
                <li>den angefangenen Test nicht neu starten können.</li>
                <li>
                  die Fragen nicht überspringen und nicht zu den vorherigen
                  Fragen zurückkehren können.
                </li>
                <li>nur EINE richtige Antwort auswählen müssen.</li>
              </ul>
            </Typography>
          </Grid>
          <Grid item xs={12} md={10} align="left">
            <FormControl component="fieldset">
              <FormLabel style={{ color: "black", fontWeight: 800 }}>
                Ich kenn ArchiCAD noch so wenig, dass ich ohne Wissenstest mit
                Schulung Modul 1 beginnen möchte
                {/* Archicad ist neu für mich und ich möchte ohne Evaluierungstest
                mit Modul I beginnen. */}
              </FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={wantTraining}
                      onChange={() => setWantTraining(!wantTraining)}
                    />
                  }
                  label="Ich möchte ohne Evaluierungstest an der Schulung teilnehmen!"
                />
              </FormGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={10}>
            <Typography variant="subtitle1" align="left">
              Wir wünschen Ihnen viel Erfolg!
              <br />
              Ihr Team Graphisoft Nord
            </Typography>
          </Grid>
          <Grid item md={10} xs={12} align="left">
            {wantTraining ? (
              <Button
                disabled={submitting}
                size="large"
                className={classes.button}
                variant="contained"
                onClick={() => trainingWithoutTest()}
              >
                Schulungsanfrage senden
              </Button>
            ) : (
              <Button
                size="large"
                className={classes.button}
                variant="contained"
                onClick={() => startQuiz()}
              >
                Evaluierungstest starten
              </Button>
            )}
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          justify="center"
          alignItems="center"
          spacing={5}
          style={{ padding: 20 }}
        >
          <Grid item xs={12} align="right">
            <Typography variant="overline" align="right">
              {evalCustomer.name} {evalCustomer.surname}
              <br></br>
              Frage {questionIndex + 1} von {setup.questions_length}
            </Typography>
          </Grid>
          <Grid item md={6} xs={6} align="left">
            <p
              style={{ fontSize: 26 }}
              dangerouslySetInnerHTML={{
                __html: makeBold(question.question || ""),
              }}
            ></p>
          </Grid>
          <Grid item md={4} xs={6} align="left">
            {question.image ? (
              <img className={classes.image} src={API_PATH + question.image} />
            ) : null}
          </Grid>
          <Grid item md={2} xs={12} align="right">
            <CircularProgressWithLabel
              variant="determinate"
              value={percent}
              text={timer}
              answer={answers[questionIndex]}
            />
          </Grid>
          <Grid item md={10} xs={12} align="left">
            <Grid container justify="center" alignItems="center" spacing={3}>
              {answerRandomArray.map((answer, index) => (
                <Grid key={index} item md={4} xs={12}>
                  <Button
                    fullWidth
                    color="primary"
                    variant="contained"
                    id={answer}
                    className={
                      active[index]
                        ? classes.buttonAnswerClicked
                        : classes.buttonAnswer
                    }
                    onClick={() => buttonClick(answer, index, question)}
                    disabled={clicked}
                  >
                    <p
                      id={answer}
                      style={{ fontSize: 16 }}
                      dangerouslySetInnerHTML={{
                        __html: makeBold(question[answer] || ""),
                      }}
                    ></p>
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item md={12} xs={12} align="right">
            <Button
              disabled={!clicked}
              className={classes.button}
              variant="contained"
              onClick={nextQuestion}
            >
              <Typography>
                {questionIndex + 1 === setup.questions_length
                  ? "Fertig"
                  : "Nächste Frage"}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      )}
    </Container>
  );
}
