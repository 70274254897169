import React, { useState } from "react";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
  IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

import PlayCircleFilledWhiteIcon from "@material-ui/icons/PlayCircleFilledWhite";
import DeleteIcon from "@material-ui/icons//Delete";
import PersonAddIcon from "@material-ui/icons/PersonAdd";

const useStyles = makeStyles({
  textfield: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#303d6b",
      },
    },
  },
  button: {
    height: "100%",
    backgroundColor: "#303d6b",
    color: "white",
  },
  addButton: {
    height: "100%",
    backgroundColor: "#303d6b",
    color: "white",
  },
  iconButton: {
    backgroundColor: "#303d6b",
    color: "white",
  },
});

const API_PATH = process.env.REACT_APP_API_URL;

const session_id = uuidv4();
const company_id = Math.random().toString(36).substr(2, 9);

const mailScheme =
  /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;

export default function FormMulti(props) {
  const { handleSnackbar, setup, setPage } = props;
  const customer_model = {
    session_id: "",
    name: "",
    surname: "",
    email: "",
    customer_id: uuidv4(),
    company_id: "",
    company_name: "",
    company_email: "",
    company_booker_name: "",
  };

  const classes = useStyles();

  const [customerArray, setCustomerArray] = useState([customer_model]);
  const [checkbox, setCheckbox] = useState(false);
  const [company_name, setCompanyName] = useState("");
  const [company_booker_name, setBookerName] = useState("");
  const [company_email, setCompanyEmail] = useState("");

  const removeUser = (index) => {
    if (index >= 0) {
      let newCustomerArray = customerArray;
      newCustomerArray.splice(index, 1);
      setCustomerArray([...newCustomerArray]);
    }
  };

  const addUser = (event, newValue) => {
    if (customerArray.length < 15) {
      let newCustomerArray = customerArray;
      newCustomerArray.push(customer_model);
      setCustomerArray([...newCustomerArray]);
    } else handleSnackbar("error", "Maximale 15 Teilnehmer");
  };

  const createCustomer = (customers, dsgvo) => {
    console.log(customers);
    console.log(customers.length);
    let valid = true;
    if (!dsgvo) {
      valid = false;
      handleSnackbar("error", "Bitte der Datenschutzerklärung zustimmen");
      return null;
    }
    if (
      company_name.length < 2 &&
      company_booker_name.length < 2 &&
      company_email < 2
    ) {
      valid = false;
      handleSnackbar("error", "Bitte die Daten eingeben");
      return null;
    }
    if (company_name.length < 2) {
      valid = false;
      handleSnackbar("error", "Bitte den Firmennamen eingeben");
      return null;
    }
    if (company_booker_name.length < 2) {
      valid = false;
      handleSnackbar("error", "Bitte den Sachbearbeiter eingeben");
      return null;
    }
    if (company_email.length > 0) {
      if (!mailScheme.test(company_email)) {
        valid = false;
        handleSnackbar("error", company_email + " entspricht nicht dem Schema");
        return null;
      }
    } else {
      valid = false;
      handleSnackbar(
        "error",
        "Bitte geben Sie die E-Mail Adresse der Firma ein"
      );
      return null;
    }
    let newCustomer = customers.map((C, index) => {
      if (C.name.length < 2 && C.surname.length < 2 && C.email < 2) {
        valid = false;
        handleSnackbar("error", "Bitte die Teilnehmer eintragen");
        return null;
      }
      if (C.name.length < 2) {
        valid = false;
        handleSnackbar(
          "error",
          "Teilnehmer " + (index + 1) + " bitte den Vornamen eingeben"
        );
        return null;
      }
      if (C.surname.length < 2) {
        valid = false;
        handleSnackbar(
          "error",
          "Teilnehmer " + (index + 1) + " bitte den Nachnamen eingeben"
        );
        return null;
      }
      if (C.email.length > 0) {
        if (!mailScheme.test(C.email)) {
          valid = false;
          handleSnackbar(
            "error",
            "Bei Teilnehmer " +
              (index + 1) +
              " " +
              C.email +
              " entspricht die E-Mail Adresse nicht dem Schema"
          );
          return null;
        }
      } else {
        valid = false;
        handleSnackbar(
          "error",
          "Bei Teilnehmer " +
            (index + 1) +
            " bitte eine E-Mail Adresse eingeben"
        );
        return null;
      }
      if (customerArray.includes(C.email)) {
        valid = false;
        handleSnackbar(
          "error",
          "Bei Teilnehmer " +
            (index + 1) +
            " " +
            C.email +
            " die E-Mail Adresse bitte nur einmal vergeben"
        );
        return null;
      }
      return {
        ...C,
        session_id,
        company_id,
        company_name,
        company_booker_name,
        company_email,
      };
    });

    if (valid) {
      axios
        .post(API_PATH + "testEmail/", { customers: newCustomer })
        .then(function (response) {
          let emailArray = response.data;
          var emails = emailArray.map(function (item) {
            return item["email"];
          });
          let emailsInUse = response.data.length;
          if (emailsInUse > 0) {
            handleSnackbar(
              "error",
              "Die E-Mail Adresse(n) " +
                emails.toString() +
                " bereits in Benutzung, bitte kontaktieren Sie uns unter schulungen@graphisoft-nord.de"
            );
          } else {
            console.log("create multi");
            axios
              .post(API_PATH + "create/customersMulti", {
                customers: newCustomer,
                setup,
              })
              .then(function (response) {
                console.log(response);
                console.log("createed");
                handleSnackbar("success", "Anfrage gesendet");
                setPage(3);
              })
              .catch(function (error) {
                console.log(error);
                handleSnackbar("error", "Fehler");
              });
          }
        });
    }
  };

  const handleChange = (prop, index) => (event) => {
    let newCustomerArray = [...customerArray];
    let newCustomer = { ...newCustomerArray[index] };
    newCustomer[prop] = event.target.value;
    newCustomerArray[index] = newCustomer;
    setCustomerArray(newCustomerArray);
  };

  return (
    <Grid direction="row" container spacing={2}>
      <Grid item xs={12} align="left" md={12}>
        <FormControl component="fieldset">
          <FormGroup aria-label="position" row>
            <FormControlLabel
              control={<Checkbox style={{ color: "#303d6b" }} />}
              label={
                <Typography>
                  {"Ich habe die "}
                  <a
                    href="http://graphisoft-nord.de/datenschutz/"
                    target="blank"
                  >
                    Datenschutzerklärung
                  </a>
                  {" gelesen und Stimme der Verarbeitung meiner Daten zu."}
                </Typography>
              }
              labelPlacement="end"
              onChange={(event) => setCheckbox(event.target.checked)}
              value={checkbox}
            />
          </FormGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12} align="left" md={10}>
        <TextField
          className={classes.textfield}
          required
          fullWidth
          id="company"
          label="Büro"
          onChange={(event) => setCompanyName(event.target.value)}
          value={company_name}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} align="left" md={10}>
        <TextField
          className={classes.textfield}
          required
          fullWidth
          id="name"
          label="Sachbearbeiter"
          onChange={(event) => setBookerName(event.target.value)}
          value={company_booker_name}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} align="left" md={10}>
        <TextField
          className={classes.textfield}
          required
          fullWidth
          id="email"
          label="E-Mail"
          onChange={(event) => setCompanyEmail(event.target.value)}
          value={company_email}
          type="email"
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} md={2} align="right">
        <Button
          className={classes.button}
          variant="outlined"
          aria-label="add"
          fullWidth
          onClick={() => createCustomer(customerArray, checkbox)}
          endIcon={<PlayCircleFilledWhiteIcon fontSize="large" />}
        >
          <Typography variant="button">Absenden</Typography>
        </Button>
      </Grid>
      <Grid
        direction="row"
        container
        spacing={2}
        alignItems="center"
        style={{ marginTop: 20 }}
      >
        <Grid item xs={6} align="left" md={8}>
          <Typography variant="h6">
            {customerArray.length + " Teilnehmer"}
          </Typography>
        </Grid>
        <Grid item xs={6} md={4} align="right">
          <Button
            className={classes.addButton}
            variant="outlined"
            aria-label="add"
            size="small"
            onClick={addUser}
            endIcon={<PersonAddIcon fontSize="large" />}
          >
            <Typography variant="button">Teilnehmer hinzufügen</Typography>
          </Button>
        </Grid>
        {customerArray.map((customer, index) => {
          return (
            <>
              <Grid item xs={12} md={3}>
                <TextField
                  className={classes.textfield}
                  required
                  fullWidth
                  name={"user_name " + (index + 1)}
                  id={index.toString()}
                  label={"Vorname"}
                  onChange={handleChange("name", index)}
                  value={customer.name}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  className={classes.textfield}
                  required
                  fullWidth
                  name={"user_surname " + (index + 1)}
                  id={index.toString()}
                  label={"Nachname"}
                  onChange={handleChange("surname", index)}
                  value={customer.surname}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={11} md={customerArray.length > 1 ? 5 : 6}>
                <TextField
                  className={classes.textfield}
                  required
                  fullWidth
                  name={"user_email " + (index + 1)}
                  onChange={handleChange("email", index)}
                  value={customer.email}
                  id={index.toString()}
                  label={"E-Mail"}
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={1} md={1} align="center">
                {customerArray.length > 1 ? (
                  <IconButton
                    className={classes.iconButton}
                    variant="outlined"
                    aria-label="delete"
                    size="small"
                    onClick={() => removeUser(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                ) : null}
              </Grid>
            </>
          );
        })}
      </Grid>
    </Grid>
  );
}
