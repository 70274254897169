import React, { useState } from "react";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

import PlayCircleFilledWhiteIcon from "@material-ui/icons/PlayCircleFilledWhite";

const useStyles = makeStyles({
  textfield: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#303d6b",
      },
    },
  },
  button: {
    height: "100%",
    backgroundColor: "#303d6b",
    color: "white",
  },
});

const API_PATH = process.env.REACT_APP_API_URL;

const session_id = uuidv4();
const company_id = Math.random().toString(36).substr(2, 9);

const customer_model = {
  session_id: session_id,
  name: "",
  surname: "",
  email: "",
  customer_id: uuidv4(),
  company_id: company_id,
  company_name: "",
  company_email: "",
  company_booker_name: "",
};

export default function FormSingle(props) {
  const { handleSnackbar, setup, setPage, setCustomer_id } = props;

  const classes = useStyles();

  const [customerSingle, setCustomerSingle] = useState({ ...customer_model });
  const [checkbox, setCheckbox] = useState(false);

  const createCustomer = (customer, dsgvo, setup) => {
    const mailScheme =
      /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    let valid = true;
    if (!dsgvo) {
      valid = false;
      handleSnackbar("error", "Bitte der Datenschutzerklärung zustimmen");
      return null;
    }
    if (
      customer.name.length < 2 &&
      customer.email.length < 2 &&
      customer.surname.length < 2
    ) {
      valid = false;
      handleSnackbar("error", "Bitte die Daten eingeben");
      return null;
    }
    if (customer.name.length < 2) {
      valid = false;
      handleSnackbar("error", "Bitte den Vornamen eingeben");
      return null;
    }
    if (customer.surname.length < 2) {
      valid = false;
      handleSnackbar("error", "Bitte den Nachnamen eingeben");
      return null;
    }
    if (customer.email.length > 2) {
      if (!mailScheme.test(customer.email)) {
        valid = false;
        handleSnackbar("error", customer.email + " ist keine E-Mail");
        return null;
      }
    } else {
      valid = false;
      handleSnackbar("error", "Bitte die E-Mail eingeben");
      return null;
    }
    if (valid) {
      axios
        .post(API_PATH + "testEmail/", { customers: [customer] })
        .then(function (response) {
          let emailArray = response.data;
          var emails = emailArray.map(function (item) {
            return item["email"];
          });
          let emailsInUse = response.data.length;
          if (emailsInUse > 0) {
            axios
              .get(API_PATH + "isCustomer/" + response.data[0].customer_id)
              .then((resp) => {
                const customer = resp.data.customer;
                const whitelist = resp.data.whitelist;
                if (whitelist) {
                  // setCustomer_id(customer.customer_id);
                  axios
                    .get(API_PATH + "resentLinkToTest/" + customer.customer_id)
                    .then(function (response) {
                      handleSnackbar(
                        "success",
                        "Sie sind bereits registriert, Ihre Domain ist auf der Whitelist und die E-Mail mit dem Link zum Test wird erneut an Sie versendet!"
                      );
                      setPage(6);
                    })
                    .catch(function (error) {
                      console.log(error);
                      handleSnackbar("error", "Fehler");
                    });
                } else {
                  if (customer.active == 2) {
                    axios
                      .get(
                        API_PATH + "resentLinkToTest/" + customer.customer_id
                      )
                      .then(function (response) {
                        handleSnackbar(
                          "success",
                          "Sie sind bereits registriert und verifiziert! Die E-Mail mit dem Link zum Test wird erneut an Sie versendet!"
                        );
                        setPage(6);
                      })
                      .catch(function (error) {
                        console.log(error);
                        handleSnackbar("error", "Fehler");
                      });
                  } else
                    handleSnackbar(
                      "error",
                      "Die E-Mail Adresse " +
                        emails.toString() +
                        " bereits in Benutzung, bitte kontaktieren Sie uns unter schulungen@graphisoft-nord.de"
                    );
                }
              });
          } else {
            axios
              .post(API_PATH + "create/customerSingle", {
                customer,
                setup,
              })
              .then(function (response) {
                handleSnackbar("success", "Anfrage gesendet");
                setPage(3);
              })
              .catch(function (error) {
                console.log(error);
                handleSnackbar("error", "Fehler");
              });
          }
        });
    }
  };

  return (
    <Grid direction="row" container spacing={2}>
      <Grid item xs={12} align="left" md={12}>
        <FormControl component="fieldset">
          <FormGroup aria-label="position" row>
            <FormControlLabel
              control={<Checkbox style={{ color: "#303d6b" }} />}
              label={
                <Typography>
                  {"Ich habe die "}
                  <a
                    href="http://graphisoft-nord.de/datenschutz/"
                    target="blank"
                  >
                    Datenschutzerklärung
                  </a>
                  {" gelesen und Stimme der Verarbeitung meiner Daten zu."}
                </Typography>
              }
              labelPlacement="end"
              onChange={() => setCheckbox(!checkbox)}
              value={checkbox}
            />
          </FormGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12} align="left" md={8}>
        <TextField
          className={classes.textfield}
          id="company"
          fullWidth
          label="Büro"
          variant="outlined"
          onChange={(event) =>
            setCustomerSingle({
              ...customerSingle,
              company_name: event.target.value,
            })
          }
          value={customerSingle.company_name}
        />
      </Grid>
      <Grid item xs={12} align="left" md={6}>
        <TextField
          className={classes.textfield}
          required
          fullWidth
          name={"user_name " + 1}
          id={"0"}
          label="Vorname"
          variant="outlined"
          onChange={(event) =>
            setCustomerSingle({
              ...customerSingle,
              name: event.target.value,
            })
          }
          value={customerSingle.name}
        />
      </Grid>
      <Grid item xs={12} align="left" md={6}>
        <TextField
          className={classes.textfield}
          required
          fullWidth
          name={"user_surname " + 1}
          id={"0"}
          label="Nachname"
          variant="outlined"
          onChange={(event) =>
            setCustomerSingle({
              ...customerSingle,
              surname: event.target.value,
            })
          }
          value={customerSingle.surname}
        />
      </Grid>
      <Grid item xs={12} align="left" md={10}>
        <TextField
          className={classes.textfield}
          required
          fullWidth
          name={"user_email " + 1}
          id={"0"}
          label="E-Mail"
          variant="outlined"
          onChange={(event) =>
            setCustomerSingle({
              ...customerSingle,
              email: event.target.value,
            })
          }
          value={customerSingle.email}
        />
      </Grid>
      <Grid item xs={12} md={2} align="right">
        <Button
          className={classes.button}
          // disabled={!props.valid}
          variant="outlined"
          aria-label="add"
          fullWidth
          onClick={() => {
            createCustomer(customerSingle, checkbox, setup);
          }}
          endIcon={<PlayCircleFilledWhiteIcon fontSize="large" />}
        >
          <Typography variant="button">Absenden</Typography>
        </Button>
      </Grid>
    </Grid>
  );
}
